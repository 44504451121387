import React from 'react'
//imports stylesheets
import './App.css'

//imports components
import Home from './Componenets/Home/Home'

const App = () => {
  return (
    <>
    <Home />
    </>
  )
}

export default App